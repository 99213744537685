/* .translation .container {
  min-height: 100px;
} */

.translation {
  margin-top: 30%;
}

.main-text-input {
  width: 100%;
  font-size: xx-large;
}

.info-container {
  text-align: left;
  font-style: italic;
  font-size: large;
}

.translation-container{
  text-align: left;
  font-size: x-large;
}

.error-text {
  color: red;
}

.action-buttons-container {
  /* margin-top: 20px; */
}

.action-button {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}

.action-button {
  background-color: var(--main-blue-color);
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

a.action-button {
  /* margin-left: 20px; */
}

a.disabled {
  background-color: lightgrey;
}

.clipboard-icon {
  float: right;
  cursor: pointer;
}
